body {
  background: rgba(226, 230, 242, 0.52) !important;
}

a {
  cursor: pointer;
}

/*
semantic-ui のスタイルを上書き
- TinyMCEのimage dialogのz-indexが、semantic-uiのModalより高い
- モーダルを表示するとTinyMCEのモーダルの裏に表示されてしまう
- semantic-uiのModalコンポーネントの実装的に、z-indexをコンポーネント側で変更できないので、CSSで変更する
*/
.ui.modals.dimmer {
  z-index: 2000;
}
